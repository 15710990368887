<template>
  <div class="padd_content">
    <div class="d-flex pa-2 mb-3 align-center">
      <blue-circle class="mr-2" />
      <h3 class="color_default">Konfirmasi Formulir</h3>
    </div>
    <v-tabs style="width: 450px;" v-model="tab" background-color="#cdf0f7" class="px-2 pb-2">
      <v-tab
        
        href="#tab1"
        class="text-capitalize mr-3 header_tab"
        active-class="active_tab"
        ><h3>Kesling Kunci</h3></v-tab
      >
      <v-tab
      
        href="#tab2"
        class="text-capitalize mr-3 header_tab"
        active-class="active_tab"
        ><h3>Kelola Limbah</h3></v-tab
      >
      
    </v-tabs>
    <v-tabs-items v-model="tab" style="background: none" class="px-2">
      
      <v-tab-item value="tab1">
        <LingkunganTab />
        <div style="height: 100px"></div>
      </v-tab-item>
      <v-tab-item value="tab2">
        <FasyankesTab />
        <div style="height: 100px"></div>
      </v-tab-item>
    </v-tabs-items>
    <div class="pa-2">
      <UpdateAllStatus
        v-bind:updateLoginStatus="updateLoginStatus"
        @close="updateLoginStatus = false"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FasyankesTab from "../../../components/Formulir/Konfirmasi/konfirmKelolaLimbah.vue";
import LingkunganTab from "../../../components/Formulir/Konfirmasi/konfirmKeslingKunci.vue";
import BlueCircle from "../../../components/General/BlueCircle";
import UpdateAllStatus from "../../../components/Pengguna/Update/updateAllStatus.vue";
export default {
  name: "DinkesKonfirmasi",
  components: {
    UpdateAllStatus,
    FasyankesTab,
    LingkunganTab,
    BlueCircle,
  },
  computed: {
    ...mapState({
      users: (state) => state.user.all_user,
    }),
  },
  data() {
    return {
      tab: "tab1",
      updateLoginStatus: false,
      loginActive: true,
    };
  },

  methods: {
    switchStatusLogin() {
      this.updateLoginStatus = true;
    },
  },
};
</script>

<style scoped>
.padd_content {
  padding: 40px 50px;
}
.v-tab {
  max-width: unset !important;
  width: 50% !important;
}
</style>