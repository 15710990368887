<template>
  <div>
    <v-card class="radius_intab custom_card pa-5">
      <div class="toolbar d-flex">
        <div style="width: 100%;">
          <v-row
            class="px-4 py-2"
            style="width: 550px;"
          >
            <div
              style="width: 310px;"
              class="mr-1"
            >
              <v-text-field
                v-model="find"
                flat
                label="Cari Fasyankes"
                class="custom_field"
                background-color="#F1FBFC"
                solo
                hide-details="auto"
                prepend-inner-icon="mdi-magnify"
                color="blue"
                @keydown="allFilter"
              />
            </div>
            <div
              style="width: 42px;"
              class="ml-1 mr-1"
            >
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="170"
                bottom
                :nudge-bottom="55"
                elevation="0"
              >
                <template #activator="{ on, attrs }">
                  <v-card
                  
                    class="d-flex "
                    outlined
                    v-bind="attrs"
                    height="100%"
                    v-on="on"
                  >
                    <v-icon class="mx-auto">
                      mdi-filter-outline
                    </v-icon>
                  </v-card>
                </template>
                <v-card
                  class="pa-2 popup_radius"
                  outlined
                  elevation="0"
                >
                  <p class="color_txt">
                    Filter Role
                  </p>
                  <v-radio-group
                    v-model="role"
                    dense
                    color="info"
                    hide-details="auto"
                  >
                    <v-radio
                      label="Semua Fasyankes"
                      value=""
                    />
                    <v-radio
                      label="Rumah Sakit"
                      value="1"
                    />
                    <v-radio
                      label="Puskesmas"
                      value="2"
                    />
                  </v-radio-group>
                  <v-card-actions>
                    <v-spacer />

                    <v-btn
                      class="txt-capitalize"
                      color="#00b4cc"
                      dark
                      depressed
                      width="100px"
                      @click="allFilter()"
                    >
                      done
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
            <div
              style="width: 141px;"
              class="ml-1"
            >
              <v-menu
                v-model="menuLimit"
                bottom
                :nudge-bottom="55"
                elevation="0"
              >
                <template #activator="{ on, attrs }">
                  <v-card
                    class="pa-3 d-flex align-center"
                    outlined
                    v-bind="attrs"
                    height="100%"
                    v-on="on"
                  >
                    <p class="ma-0 mr-2 color_txt small_txt mx-auto">
                      Show : {{ limit }}
                    </p>
                    <v-icon class="mx-auto">
                      mdi-chevron-down
                    </v-icon>
                  </v-card>
                </template>
                <v-card
                  class="popup_radius"
                  outlined
                  elevation="0"
                >
                  <v-list>
                    <v-list-item
                      link
                      @click="changeLimit(10)"
                    >
                      <v-list-item-title>10</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      @click="changeLimit(50)"
                    >
                      <v-list-item-title>50</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      @click="changeLimit(100)"
                    >
                      <v-list-item-title>100</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          </v-row>
        </div>
      </div>
      <v-skeleton-loader
        v-if="loading"
        type="table-tbody"
      />

      <div
        v-if="listData && !loading"
        class="mt-5"
      >
        <v-data-table
          :headers="headers"
          :items="listData"
          :page.sync="page"
          :items-per-page="limit"
          class="elevation-0 color_txt"
          hide-default-footer
          :item-class="row_classes"
          :single-select="false"
        >
          <template #[`item.report_updatedTime`]="{ item }">
            {{ $date(item.report_updatedTime).format("DD MMMM YYYY") }}
          </template>
          <template #[`item.isActive`]="{ item }">
            <v-switch
              v-if="item.isActive"
              v-model="item.reportcol"
              hide-details="auto"
              class="mt-0"
              inset
              @click.stop="changeStatus(item)"
            />
          </template>
          <template #[`item.reportcol`]="{ item }">
            <div
              :class="
                item.reportcol == null
                  ? 'nofill'
                  : item.reportcol == '0'
                    ? 'unsend'
                    : item.reportcol == '1'
                      ? 'sent'
                      : item.reportcol == '2'
                        ? 'confirm'
                        : 'repair'
              "
            />
          </template>
          <template #[`item.konfirmasi`]="{ item }">
            <p
              v-if="item.reportcol == '1'"
              class="ma-0"
            >
              Belum dikonfirmasi
            </p>
            <p
              v-if="item.reportcol == '2'"
              class="ma-0"
            >
              Sudah dikonfirmasi
            </p>
            <p
              v-if="item.reportcol == '3'"
              class="ma-0"
            >
              perlu perbaikan
            </p>
          </template>
          <template #[`item.actions`]="{ item }">
            <div v-if="item.reportcol == '2'">
              <v-btn
                small
                depressed
                color="#00ccb6"
                class="mr-1 action_btn"
                min-width="10px"
                dark
                @click="createBeforeConfirm(item.report_id)"
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
              <v-btn
                small
                depressed
                color="#00879a"
                class="mr-1 px-1"
                min-width="10px"
                dark
              >
                <v-icon>mdi-cloud-download-outline</v-icon>
              </v-btn>
            </div>
            <div v-if="item.reportcol == '1' || item.reportcol == '3'">
              <v-btn
                v-if="!loadConfirm"
                small
                depressed
                color="#00B4CC"
                class="mr-1 action_btn"
                min-width="10px"
                dark
                @click="createBeforeConfirm(item.report_id)"
              >
                Konfirmasi Data
              </v-btn>
              <v-btn
                v-if="loadConfirm && item.report_id == tempId"
                small
                depressed
                color="#00B4CC"
                class="mr-1 action_btn"
                min-width="10px"
                dark
              >
                Loading...
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <div class="d-flex justify-center pa-3">
          <div class="d-flex align-center pa-2 round_paginate">
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('prev')"
            >
              <v-icon small>
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <div class="mx-3">
              <p class="ma-0 color_default">
                {{ page }} of {{ total_page }}
              </p>
            </div>
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('next')"
            >
              <v-icon small>
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="px-2 mt-7">
          <div class="d-flex align-center legend">
            <div class="round-info bg-sucs me-2" />
            <p>Formulir Telah Terkirim dan Terkonfirmasi</p>
          </div>
          <div class="d-flex align-center legend">
            <div class="round-info bg-perbaikan me-2" />
            <p>Formulir Perlu Perbaikan</p>
          </div>
          <div class="d-flex align-center legend">
            <div class="round-info bg-terkirim me-2" />
            <p>Formulir Telah Terkirim</p>
          </div>
          <div class="d-flex align-center legend">
            <div class="round-info bg-belum me-2" />
            <p>Formulir Belum Terkirim</p>
          </div>
          <div class="d-flex align-center legend">
            <div class="round-info red me-2" />
            <p>Formulir Tidak Terisi</p>
          </div>
        </div>
      </div>
    </v-card>
    <div class="pa-2" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "KonfirmasiKelolaLimbah",
  computed: {
    ...mapState({
      fasyankes: (state) => state.formulir.get_konfirmasi_fasyankes,
    }),
    mutatedUser() {
      let data = null;
      if (this.fasyankes) {
        data = this.fasyankes.data.data.data;
      }
      return data;
    },
  },

  components: {},
  data() {
    return {
      tipe_form: "limbah fasyankes",
      listData: null,
      loading: false,
      loadConfirm: false,
      tempId: null,
      dataUser: null,
      role: "",
      page: 1,
      limit: 10,
      find: "",
      menu: false,
      menuLimit: false,
      headers: [
        {
          text: "Nama Fasyankes",
          align: "start",
          value: "nama_instansi",
        },
        {
          text: "Status",
          align: "start",
          value: "reportcol",
        },
        {
          text: "Tanggal Submit",
          align: "start",
          value: "report_updatedTime",
        },
        {
          text: "Status Konfirmasi",
          align: "start",
          value: "konfirmasi",
        },
        { text: "Aksi", sortable: false, value: "actions" },
      ],
      isNext: "",
      isPrev: "",
      total_page: null,
    };
  },
  mounted() {
    this.allFilter();
  },
  methods: {
    createBeforeConfirm(item) {
      this.tempId = item;
      let data = {
        report_id: item,
      };
      this.$store
        .dispatch("formulir/createBeforeKonfirmasi", data)
        .then((data) => {
          console.log(data);
          this.$router.push(`/konfirmasi/formulir/${item}`);
        });
    },

    changeLimit(val) {
      this.limit = val;
      this.allFilter("limit");
    },
    pagination(state) {
      if (state == "next") {
        if (this.isNext) {
          this.page++;
          this.allFilter();
        }
      } else {
        if (this.isPrev) {
          this.page--;
          this.allFilter();
        }
      }
    },
    allFilter(type) {
      this.loading = true;
      this.page = type == "limit" ? 1 : this.page;
      this.menu = false;
      let body = {
        role: this.role,
        find: this.find,
        limit: this.limit,
        tipe_form: this.tipe_form,
        page: this.page,
      };
      let data = {
        status: null,
        body: body,
      };
      this.$store
        .dispatch("formulir/filterAllinOne", data)
        .then((data) => {
          this.listData = data.data;
          let arr = [];
          data.data.forEach((el) => {
            arr.push(el.report_id);
          });
          this.loading = false;
          this.isNext = data.next_page_url;
          this.isPrev = data.prev_page_url;
          this.total_page = Math.ceil(data.total / this.limit);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    delUser(item) {
      this.dataUser = item;
      this.dialogDeleteUser = true;
    },
    changeStatus(item) {
      this.dataUser = item;
      this.dialogChangeStatus = true;
    },
    viewUser(item) {
      this.dataUser = item;
      this.dialogUpdateUser = true;
      this.$refs.update.getPerson(item.id);
    },
    row_classes(item) {
      let idx = this.fasyankes.data.data.data.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
  },
};
</script>

<style scoped>
.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}
.round_paginate {
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}
.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}
.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}
.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}
.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}
.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}
</style> 